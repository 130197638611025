@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.min";
@import "https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic&subset=latin,cyrillic";

// Media queries breakpoints
// --------------------------------------------------
// --------------------------------------------------
 




body {
  font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 2rem;
}

/* latin-ext */
@font-face { 
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v11/eFRpvGLEW31oiexbYNx7Y_esZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v11/GtRkRNTnri0g82CjKnEB0Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v11/dPJ5r9gl3kK6ijoeP1IRsvY6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v11/EsvMC5un3kjyUhB9ZEPPwg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/H2DMvhDLycM56KNuAtbJYA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@media print {
  a[href]:after {
    content: none !important;
  }
  #dataRxForm,#dashboard-style,.widget-submit,.checkbox,.bottom-border,.hide-print{
    display: none;
  }
  #homepage{
    padding-bottom: 0px!important;
    padding-top: 0px!important;
  }
 
}

// Extra small screen / phone
$screen-xs:                  1px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

//Additional breakpoint at 480;

$screen-tn:                  480px !default;
$screen-tn-min:              $screen-tn;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum

$screen-tn-less:             ($screen-tn-min - 1) !default;
$screen-xs-max:              ($screen-tn-min - 1) !default;
$screen-tn-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              1599px !default;


//override bootstrap border-radius
$border-radius-none:        0px !default;

// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns:              24 ;

$grid-min-width:            768px;
$tablet-md-width:           850px;

// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width:         20px;

/*dimensions*/
$container-max-width:1700px;

/* fonts */
$defaultfont: 'Lato', sans-serif;

/* colors */
$darkgray: #2b2f3b;
$darkgrayalt: #4C4C4E;
$lightgray: #95979e;
$reallylightgray: #efefef;
$white: #ffffff;
$lightgraybackground: #F2F2F2;
$lightgreenish: #B8C1BC;
$lightgreenishalt:#B5B5B5;
$green: #64BD8D;
$red: #FB4A45;
$black: #000000;
/* HighCharts colors */
$chartskyblue: #75B6CA;
$chartblue: #01497C;


$modal-lg-alt:                    900px !default;
$modal-md-alt:                    900px !default;
$modal-sm:                    300px !default;

html,body { font-family: $defaultfont; }


body { 
    font-size: 13px;
    opacity: 1; 
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}

.fa-btn {
    margin-right: 6px;
}

.tw-fav-icon { cursor: pointer; }

.btn {
    border-radius: $border-radius-none;
}
.btn-tn{
  padding: 3px 5px!important;
  margin-bottom: 1px;
}
.bold-unread{
    font-weight: bolder;
}
.btn-tn2{
  padding: 3px 6.5px!important;
  margin-bottom: 1px;
}
.width120{
  width: 120px;
}
.form-control {
    border-radius: $border-radius-none;
    height: $input-height-base + 5;

    &.bg-danger {
        background-color: $state-danger-bg;
    }

    &.bg-success {
        background-color: $state-success-bg;
    }
}

.pagination {
    border-radius: $border-radius-none;
    &>li:first-child>a, &>li:first-child>span {
        border-radius: $border-radius-none;
    }
    &>li:last-child>a, &>li:last-child>span {
        border-radius: $border-radius-none;
    }
}

.panel, .panel-heading {
    border-radius: $border-radius-none;
}
.margin-bottom-10{
  margin-bottom: 10px;
}
.btn.btn-success {
    background: $green;
    border-color: $green;
}

.fullwidth { position: relative; margin: 0; padding: 0; overflow: hidden; width: 100%; }
.tw-spacer { min-height: 40px; clear:both; }

.forcewhite {
    background: $white !important;
}

.tw-content-area {
    padding-bottom:40px;
    &:nth-child(odd) {
        background-color: $lightgraybackground;
    }
}

.tw-alternatecolor {
    &.tw-lightgraybackground { background-color: $lightgraybackground; }
    &.tw-white { background-color: $white; }
}
@media (min-width: 320px) and (max-width: 380px){
  body{
    font-size: 10px;
  }
}
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
    .modal-dialog {
        width: $modal-md-alt;
        max-width: 95%;
    }
}

@media (max-width: $screen-xs-max) {
    .text-center-xs {
      text-align: center;
    }
}

.modal-content {
    border-radius: $border-radius-none;
}

.errormsg {
    white-space: pre; padding:15px; text-align: center; color: darkred; word-wrap: break-word;
}

.successmsg {
    white-space: pre; padding:15px; text-align: center; color: darkgreen; word-wrap: break-word;
}

.divlisting {
    min-height: 40px;

    &.bg-default {
        background: #FBFBFB;
    }

    &:hover {
        background-color: $state-success-bg;
    }

    .divlistingcell {
        margin-top: 10px;
    }

    .divlistingcellbtns {
        display: block;
        margin-top: 3px;
    }
}

.datepicker.dropdown-menu {
    border-radius: $border-radius-none;
}

.input-daterange input.form-control {
    border-radius: $border-radius-none !important;
}


//Email Templates Start///
.email-templates{
    .padding-bottom40{
        padding-bottom: 40px;
    }
    .padding-bottom20{
        padding-bottom: 20px;
    }
    .span-shortcode{
        color: #389DD7;
        cursor: pointer;
    }
    .fileUpload {
        position: relative;
        overflow: hidden;
    }
    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
    table tbody tr:hover{
        background-color: #dff0d8;
    }
}

.navbar-nav > li > .dropdown-menu {
    width:100%;
    min-width: 100%;
    border-radius: $border-radius-none;
}
//Email Templates End///

.searchdist {
    min-width: 210px;
}
.searchdist-width {
    width:100%!important;
}
.no-padding {
    padding: 0!important;
  }
@media (min-width: $screen-md-max){
  .no-padding3 {
        padding: 0!important;
      }
}
@media (min-width: $screen-md-min){
  .no-padding2 {
        padding: 0!important;
      }
}
.sortable a {
    color: $white;
    font-weight: bold;
}
.sortableDashboard a {
    color: $black;
    font-weight: bold;
}

.nonsortable {
    color: $reallylightgray;
}

.listbtn {
    height: 39px;
    width: 83px;
    &.clearbtn {
      width: 39px;
      .fa.fa-remove {
        top: 2px;
        position: relative;
        left: -1px;
      }
    }

    @media (max-width: $screen-xs-max) {
      margin-top: 10px;
    }
}

.loadmore{
  display: none;
}
/* Start Block Layout */
  .img-layout{
    width: 30px;
    margin-right: 5px;
    text-decoration: none;
  }
  .img-layout:hover{
    width: 30px;
    margin-right: 5px;
    text-decoration: none;
    border: solid 2px white;
  }
  .img-layout-active{
    width: 30px;
    margin-right: 5px;
    text-decoration: none;
    border: solid 2px white;
  }
  .padding-top-5{
    padding-top: 5px;
  }
  .blueish{
    background-color: #d9edf7;
    padding: 7px;
  }
  footer p{
    font-size:12px!important;
  }
  footer p:first-of-type{
    margin: 0 0 5px!important;
  }
  .table-iframe{
    font-size: 11!important;
  }
  .table-parent{
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    overflow: hidden;
    display:block
  }
  .fix-div{
    position: absolute;
    left: 0;
    top: 0;
    width: 35%;
    overflow: hidden;
    padding-top: -15px!important;
  }
  .mobile-div{
    overflow-y: hidden;
    margin-left: 35%;
  }
  
  .table-width {
    min-width: 300px!important;
  }
  @media (min-width: 446px) {
    .fix-div{
      padding-top: -15px!important;
    }
  }
  @media (min-width: 10px) and (max-width: 445px) {
    .fix-div{
      padding-top: 30px!important;
    }
  }
  @media (max-width: $screen-xs-min) {
    .fix-div{
      overflow-x: scroll;
    }
    .mobile-div{
      overflow: scroll;
    }
  }
/* End Block Layout */

/* Frontend Start */
.homepage{
  .bottom-border{
    border-bottom: solid 2px #DDDDDD;
    padding:20px 0!important;
    margin-bottom: 20px;
  }
  span.small-bold-text{
    font-weight: bold!important;
    font-size: 13px!important;
    vertical-align: middle;
  }
  span.medium-bold-text{
    font-weight: bold!important;
    font-size: 16px!important;
    vertical-align: middle;
  }
  span.big-bold-text{
    font-weight: bold!important;
    font-size: 18px!important;
    vertical-align: middle;
  }
  .chart{
    min-width: 260px;
    height: 400px;
    margin: 0 auto;
  }
  .pharmacy-chart{
    width: 100%;
    height: 300px;
  }
  .chart-sky-blue{
    color: $chartskyblue;
  }
  .chart-blue{
    color: $chartblue;
  }
  .float-right{
    float:right;
  }
  .height-fix{
    padding: 4px;
    min-height: 570px;
  }
  .checkbox label{
    padding: 0!important;
  }
  .margin-top-bottom-10{
    margin: 10px 0!important;
  }
  .padding-bottom30{
    padding-bottom: 30px;
  }
  .table-nopadding > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    padding: 0!important;
  }
  .earnings-submit,.claims-submit,.topdrugs-submit,.pharmacies-submit{
    height: 39px;
  }
  .widget-submit{
    height: 39px;
  }
  input[type='checkbox']{
    margin-left: -16px;
  }
  .z-index{
    z-index: 999!important;
  }
  a {
    text-decoration: none;
  }
  @media(max-width: 470px){
    #top-drugs,#top-pharmacy{
      table{
        table-layout: fixed;
      }
      table td{
        white-space:pre; 
        overflow: hidden;
        font-size: 12px!important;
      }
    }
    span.medium-bold-text{
      font-weight: bold!important;
      font-size: 13px!important;
      vertical-align: middle;
    }
    .xs-width{
      width: 42%;
    }
  }
  @media(max-width: $screen-md){
    .submit-padding{
      padding-top: 10px;
    }
  }
  @media(min-width: $screen-md-min) and (max-width: $screen-lg-min){
    #top-drugs table{
      font-size: 12px!important;
    }
  }
  @media(min-width:  $screen-md-min){
    .border-left{
      border-left: solid 1px #EEEEEE;
    }
    .border-right{
      border-right: solid 1px #EEEEEE;
    }
    .border-bottom{
      border-bottom: solid 1px #337ab7;
    }
    .no-padding{
      padding: 0!important;
    }
  }
}
#footer{
    color: #fff;
    background-color: #337ab7;
    border-color: 1px #337ab7;
    text-align:center;
  }
@media(min-width: $screen-xs-min) and (max-width: $screen-sm-max){
  .height-fix{
    min-height: 660px!important;
  }
}

@media(max-width: 320px){
  body{
    font-size: 12px!important;
  }

}
/* LOGO and Header*/
.navbar-collapse{
  background-color: rgba(255,255,255,0);
  border-color:  rgba(255,255,255,0);
}

.navbar-default {
    background-color: rgba(255,255,255,0);
    border-color:  rgba(255,255,255,0);
}
.navbar-corner{
    float: left;
    font-size: 18px;
    line-height: 20px;
    height: 20px;
}
.navbar-corner img{
  width: 40%!important;
}
.body{
  background-size: 34%;
  background-image: url("../images/corner.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top; 
}
.login-page{
  width: 50%;
  padding-top: 40px;
}
@media(min-width: 320px) and (max-width: 768px){
  .img-xs{
    width: 200px!important;
    max-width: 200px!important;
  }
  .navbar-collapse{
    background-color: #fff;
    border-color: #fff;
  }
  .navbar-collapse ul:hover{
    background-color: #ddd!important;
    border-color:  #ddd!important;
  }
}
.deselect{
  width: 190px!important;
}
@media(min-width: 768px){
  .padding-left{
    padding-left: 20%;
  }
}
/* Dashboard Distriburor Info Start */
.dashboard-info{
  span.medium-bold-text{
    font-weight: bold!important;
    font-size: 16px!important;
    vertical-align: middle;
  }
  .anchor{
    color: #99DDF3;
  }
  .anchor:hover{
    text-decoration: underline;
  }
  .dashboard-message{
    font-weight: bold!important;
    font-size: 15px!important;
    font-style:  italic;
    vertical-align: middle;
    color: #588BB0;
  }
  .dashboard-message-nr{
    color: #99DDF3;
  }
  .red{
    color: $red!important;
  }
  .padding-bottom-10{
    padding-bottom: 13px;
  }
  .padding-bottom-20{
    padding-bottom: 16px;
  }
  @media(min-width: $screen-sm-min){
    .float-right{
      float: right;
    }
  }
}
/* Dashboard Distriburor Info End */


/* Admin Area */
#view-distributor-data {
    .claims-by-location .city-row {
        color: #8E8E8E;
    }

    .claims-by-location .city-row td:first-child {
        padding-left: 20px;
    }
    
    .filter-row {
        margin-bottom: 30px;
    }
    
    .table-headline-title {  
        background-color: #d9edf7;
        
        th {
             text-align: center; 
             padding: 3px;
        }
    }
}

#list-distributor-data {
    .searchdist {
        width: 100%;
    }
    
    .no-padding {
        padding: 0;
    }
    
    @media (max-width:479px){
        .divlistingcell .btn {
            padding: 7px;
        }
    }
}

.ajax-loader {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url('../images/ajax-loader.gif');
}

.loading-element {
    text-align: center;
    margin: 10px 0;    
}

#search-override {
    min-width: 150px;
}

.divlisting .checkbox-col {
    padding-top: 10px;
}

.col-table-cell {
    /* display: table-cell;   */ 
    float: none;
    vertical-align: middle;
}

.send-mail-step-left {
    /* border-right: solid 1px #337AB7;   */ 
    
    &.step-2 {
        padding-left: 25px;
        padding-right: 9px;
    }
}

.send-pdf-email h3 {
    margin: 0;
}

.remove-selected-user {
    color: #ff0000;
    cursor: pointer;
}

.template-preview-body {
    padding: 10px;
    max-height: 400px;
    overflow: auto;
}

.include-pdf-sel-dates,
.include-pdf-sel-dates > div {
    padding: 0;
}

.send-mail-step-left .template-preview {
    margin: 40px 20px;
}

.send-summary-container {
    padding: 10px 20px 10px 10px;   
}

.send-summary-inc-pdf {
    display: none;
}

/* User Increase */
.red-input-alert{
  border: solid 2px red;
}

.anchorLink,.anchorHeader{
  color:blue;
  text-decoration: underline;
}
.anchorLink,.anchorHeader:hover{
  cursor: pointer;
  text-decoration: underline;
  color:blue;
}
#loading{
    background-color: white;
    z-index: 2000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    .loading-img{
        position: absolute;
        left: 50%;
        top: 50%;
    }
}

#loadingunu,#loadingdoi,#loadingtrei,#loadingpatru{
  background-color: white;
  z-index: 2000;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  .loading-img{
      position: absolute;
      left: 50%;
      top: 50%;
  }
}

#increase-wrap,#increase-wrap2{
  padding: 20px;
  @media (min-width: $screen-xs) and (max-width: $screen-sm){
    .hide-mobile{
      display: none;
    }
    .table-increase{
      width: 100%!important;
    }
    .display-block{
      display: block;
    }
    #save_pay_rate,#save_select_override,#save_override,#cancel_override,#cancel_payrate{
      margin: 5px;
      width: 100%;
      margin-left: 0px !important;
    }
  }
}

.distributor_files {
  visibility: hidden;
  position: absolute;
}